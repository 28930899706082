<template>
  <app-modal-subscriber-payment-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :subscriber="selectedSubscriber"
    @update:subscriber="selectedSubscriber = $event"
  />
  <div>
    <app-table
      :loading="apiPaginationLoading"
      :apiResponse="apiPaginationResponse.data ?? []"
      :showNumber="false"
      @on-item-click="onItemClick"
      @empty-button-clicked="createNewSubscriber"
      @params-changed="paramsChanged"
      :filters="[
        {
          key: 'filter[name]',
          type: 'text',
          placeholder: $t('subscription.table_header.subscriber'),
          value: '',
        },
        {
          key: 'filter[email]',
          type: 'text',
          placeholder: $t('subscription.table_header.subscriber_email'),
          value: '',
        },
        {
          key: 'filter[phone_no]',
          type: 'text',
          placeholder: $t('subscription.table_header.subscriber_phone_no'),
          value: '',
        },
      ]"
    >
      <template v-slot:title>
        <div>
          <button
            class="flex gap-2 items-center"
            @click="$router.push({ name: 'subscription.package.index' })"
          >
            <app-icon name="ArrowLeftIcon" class="w-4" />
            <div class="flex font-medium gap-1">
              <p>{{ $t("subscription.subscriber.list_of_subscribers") }}</p>
              <p>{{ this.$route.params.package_name }}</p>
            </div>
          </button>
        </div>
      </template>
      <template v-slot:button>
        <div class="flex flex-row space-x-2">
          <app-button
            width="w-1/8"
            :showf70Icon="false"
            @click="createNewSubscriber"
            :loading="apiPaginationLoading"
          >
            <template v-slot:icon>
              <app-icon-outline
                name="PlusIcon"
                class="h-4 w-4 text-white mr-2"
              />
            </template>
            {{ $t("subscription.subscriber.add_subscriber_button") }}
          </app-button>
        </div>
      </template>
      <template v-slot:header>
        <th class="font-bold text-center">{{ $t("subscription.table_header.status") }}</th>
        <th class="font-bold">
          {{ $t("subscription.table_header.subscriber") }}
        </th>
        <th class="font-bold">
          {{ $t("subscription.table_header.subscriber_email") }}
        </th>
        <th class="font-bold">
          {{ $t("subscription.table_header.subscriber_started_date") }}
        </th>
        <th class="font-bold">
          {{ $t("subscription.table_header.subscriber_next_bill_date") }}
        </th>
        <th class="font-bold">{{ $t("general.actions") }}</th>
      </template>
      <template v-slot:body="slotData">
        <td>
          <app-badge
            rounded="rounded-full"
            padding="px-3"
            :status="getStatus(slotData.model?.status)"
          >
            {{ $t("subscription.subscriber.status." + slotData.model?.status) }}
          </app-badge>
        </td>
        <td>
          {{ slotData.model?.name ?? "-" }}
        </td>
        <td>
          {{ slotData.model?.email ?? "-" }}
        </td>
        <td>
          {{
            slotData.model.start_at
              ? $moment(slotData.model.start_at).format("DD MMM YYYY hh:mm a")
              : "-"
          }}
        </td>
        <td>
          {{
            slotData.model.next_charge_at
              ? $moment(slotData.model.next_charge_at).format(
                  "DD MMM YYYY hh:mm a"
                )
              : "-"
          }}
        </td>
        <td>
          <div class="flex space-x-2 text-gray-400">
            <app-button-outline
              padding="py-1.5 px-4"
              @click.stop="onShowChangePackage(slotData.model)"
            >
              <p class="text-xs">{{ $t("general.change") }}</p>
            </app-button-outline>
            <app-button-outline
              padding="py-1.5 px-4"
              @click.stop="onShowCancelPackage(slotData.model)"
            >
              <p class="text-xs">{{ $t("general.cancel") }}</p>
            </app-button-outline>
          </div>
        </td>
      </template>
    </app-table>
  </div>

  <app-modal
    :show="showChangePackage"
    :showCancelButton="false"
    @close="onCloseChangePackage"
  >
    <template v-slot:title>
      <p class="pt-3">
        {{ $t("subscription.subscriber.change_package_title") }}
      </p></template
    >
    <template v-slot:body>
      <app-form-select
        class="w-full"
        :datas="packages"
        :labelText="$t('subscription.subscriber.change_package_label')"
        :loading="loadingPackages"
        :allowFilter="false"
        :showLabel="true"
        v-model="newPackage"
        :showListTitle="$lodash.isEmpty(packages)"
        :listTitleText="$t('subscription.no_package_available')"
        v-on:retry="getChangePackages()"
      />
    </template>
    <template v-slot:action>
      <app-button
        class="w-full capitalize"
        @click="onShowConfirmOrProceedChangePackage"
        :loading="loadingChangePackage"
        :showf70Icon="false"
      >
        {{ $t("subscription.subscriber.change_package_button") }}
      </app-button>
    </template>
  </app-modal>

  <app-modal
    :show="showConfirmChangePackage"
    @close="showConfirmChangePackage = false"
  >
    <template v-slot:title>{{
      $t("subscription.subscriber.confirm_change_title")
    }}</template>
    <template v-slot:body>
      <p>{{ $t("subscription.subscriber.confirm_change_desc") }}</p>
    </template>
    <template v-slot:action>
      <app-button
        @click="onClickChangePackage"
        :loading="loadingChangePackage"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-success
          hover:bg-success
          text-white
          outline-none
          capitalize
        "
      >
        {{ $t("subscription.subscriber.change_package_button") }}
      </app-button>
    </template>
  </app-modal>

  <app-modal
    :show="showConfirmCancelPackage"
    @close="showConfirmCancelPackage = false"
  >
    <template v-slot:title>
      <p class="pt-3">
        {{ $t("subscription.subscriber.cancel_title") }}
      </p></template
    >
    <template v-slot:body>
      <p>
        {{
          selectedSubscriber.status === "active"
            ? $t("subscription.subscriber.cancel_active_desc")
            : $t("subscription.subscriber.cancel_desc")
        }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @click="onClickCancelPackage"
        :loading="loadingChangePackage"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
          text-white
          outline-none
          capitalize
        "
      >
        {{ $t("subscription.subscriber.cancel_button") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import appModalSubscriberPaymentDetail from "../components/item/app-modal-subscriber-payment-detail.vue";
export default {
  components: { appModalSubscriberPaymentDetail },
  data() {
    return {
      showModalDetail: false,
      showChangePackage: false,
      showConfirmChangePackage: false,
      showConfirmCancelPackage: false,
      selectedSubscriber: null,
      newPackage: null,
      filters: "",
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["subscriptionStore/apiPaginationResponse"];
    },

    apiPaginationLoading() {
      return this.$store.getters["subscriptionStore/apiPaginationLoading"];
    },

    packages() {
      return this.$store.getters["subscriptionStore/packages"];
    },

    loadingPackages() {
      return this.$store.getters["subscriptionStore/loadingPackages"];
    },

    loadingChangePackage() {
      return this.$store.getters["subscriptionStore/loadingChangePackage"];
    },

    message() {
      return this.$store.getters["subscriptionStore/message"];
    },
  },

  beforeMount() {
    this.fetchListSubscriber();
    this.getChangePackages();
  },

  methods: {
    async fetchListSubscriber() {
      this.$store.dispatch("subscriptionStore/retrieveSubscriber", {
        package_id: this.$route.params.package_id,
        filters: this.filters,
      });
    },
    async getChangePackages() {
      await this.$store.dispatch(
        "subscriptionStore/getPackageChangePackages",
        this.$route.params.package_id
      );
    },
    getStatus(status) {
      switch (status) {
        case "created":
          return "light";
        case "active":
          return "success";
        case "canceled":
          return "error";
        case "incomplete":
        case "to_be_canceled":
          return "warning";
        case "completed":
          return "success";
        case "deactivate":
          return "error";
        default:
          return "light";
      }
    },
    createNewSubscriber() {
      this.$store.commit("subscriptionStore/clearSubscriber");
      this.$router.push({
        name: "subscription.subscriber.create",
        query: { package_id: this.$route.params.package_id },
      });
    },

    async onItemClick(data) {
      let result = await this.$store.dispatch(
        "subscriptionStore/retrieveSubscriberById",
        { package_id: this.$route.params.package_id, subscriber_id: data._id }
      );
      this.selectedSubscriber = result.data;
      this.showModalDetail = true;
    },

    onShowChangePackage(subscriber) {
      this.getChangePackages();
      this.selectedSubscriber = subscriber;
      if (this.packages.length > 0) {
        this.newPackage = this.packages[0];
      }

      this.showChangePackage = true;
    },
    
    onCloseChangePackage() {
      this.$store.commit("subscriptionStore/setPackages", []);
      this.showChangePackage = false;
    },

    onShowCancelPackage(subscriber) {
      this.selectedSubscriber = subscriber;
      this.showConfirmCancelPackage = true;
    },

    paramsChanged(filters, queryParams) {
      this.filters = queryParams;
      this.fetchListSubscriber();
    },

    async onShowConfirmOrProceedChangePackage() {
      if (this.selectedSubscriber.status === "active") {
        this.showChangePackage = false;
        this.showConfirmChangePackage = true;
        return;
      }

      await this.onClickChangePackage();
    },

    async onClickChangePackage() {
      let changePackageResult = await this.$store.dispatch(
        "subscriptionStore/changePackage",
        {
          package_id: this.selectedSubscriber.package_id,
          subscriber_id: this.selectedSubscriber._id,
          new_package_id: this.newPackage.id,
        }
      );

      if (changePackageResult == null) {
        this.$notify(
          {
            group: "error",
            title: this.message,
          },
          5000
        );
        return;
      }

      if (this.showConfirmChangePackage) {
        this.$notify(
          {
            group: "success",
            title: this.$t("subscription.subscriber.confirm_change_email"),
          },
          5000
        );
        this.showConfirmChangePackage = false;
      } else {
        this.showChangePackage = false;
        this.fetchListSubscriber();
      }
      this.clearSelection();
    },

    async onClickCancelPackage() {
      let changePackageResult = await this.$store.dispatch(
        "subscriptionStore/cancelPackage",
        {
          package_id: this.selectedSubscriber.package_id,
          subscriber_id: this.selectedSubscriber._id,
        }
      );

      if (changePackageResult == null) {
        this.$notify(
          {
            group: "error",
            title: this.message,
          },
          5000
        );
        return;
      }

      this.showConfirmCancelPackage = false;
      if (this.selectedSubscriber.status === "active") {
        this.$notify(
          {
            group: "success",
            title: this.$t("subscription.subscriber.confirm_change_email"),
          },
          5000
        );
      } else {
        this.fetchListSubscriber();
      }
      this.clearSelection();
    },

    clearSelection() {
      this.selectedSubscriber = null;
      this.newPackage = null;
    },
  },
};
</script>