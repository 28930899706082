<template>
  <app-full-modal :title="$t('subscription.detail.title_modal')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="subscriber">
        <div class="space-y-8">
          <!-- Payment Header -->
          <div>
            <div class="flex space-x-6 p-5">
              <div>
                <p class="text-gray-400 text-xs mb-2">
                  {{ $t("subscription.detail.amount_to_pay") }}
                </p>
                <div class="place-items-center font-bold">
                  {{
                    $formats.currency(
                      subscriber.package?.currency,
                      subscriber.package?.amount ?? 0.0
                    )
                  }}
                </div>
              </div>
              <div class="">
                <p class="text-gray-400 text-xs mb-2">
                  {{ $t("subscription.detail.next_bill_date") }}
                </p>
                <div class="place-items-center font-bold">
                  {{
                    subscriber.next_charge_at
                      ? $moment(subscriber.next_charge_at).format("DD MMM YYYY")
                      : "-"
                  }}
                </div>
              </div>
              <div class="">
                <p class="text-gray-400 text-xs mb-2">
                  {{ $t("subscription.detail.status_title") }}
                </p>
                <div class="flex space-x-2 place-items-center font-bold">
                  <app-badge
                    class="text-xs"
                    :status="getStatus(subscriber.status)"
                  >
                    {{
                      $t("subscription.subscriber.status." + subscriber.status)
                    }}
                  </app-badge>
                  <app-button-copy
                    v-if="subscriber.status != 'active' && subscriber.status != 'to_be_change_package'"
                    :title="$t('subscription.subscriber.copy_activation_link')"
                    :textToCopy="subscriber.activation_url"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Payment Detail -->
          <div
            class="flex space-x-6 text-xs p-5"
            v-if="subscriber?.latest_payment_link"
          >
            <div class="space-y-4">
              <p class="text-gray-400">
                {{ $t("subscription.detail.last_payment") }}
              </p>
              <p>
                {{
                  subscriber?.last_charge_at != null
                    ? $moment(subscriber.last_charge_at).format(
                        "DD MMM YYYY hh:mm a"
                      )
                    : "-"
                }}
              </p>
            </div>
            <div class="bg-divider-gray w-0.5"></div>
            <div class="space-y-4">
              <p class="text-gray-400">
                {{ $t("subscription.detail.next_payment") }}
              </p>
              <p>
                {{
                  subscriber?.next_charge_at != null
                    ? $moment(subscriber.next_charge_at).format("DD MMM YYYY")
                    : "-"
                }}
              </p>
            </div>
            <div class="bg-divider-gray w-0.5"></div>
            <div class="space-y-3">
              <p class="text-gray-400">
                {{ $t("subscription.detail.method") }}
              </p>
              <div class="flex space-x-2 place-items-center">
                <p>
                  {{
                    subscriber?.latest_payment_link?.attempts[0].payment_method
                      .name ?? "-"
                  }}
                </p>
                <img
                  class="h-5"
                  :src="
                    subscriber?.latest_payment_link?.attempts[0].payment_method
                      .image_url
                  "
                />
              </div>
            </div>

            <div></div>
          </div>

          <!-- Payment History -->

          <div>
            <div
              class="space-y-2 p-5"
              v-for="(attempt, index) in reverseAttempt()"
              :key="attempt._id"
            >
              <div>
                <p class="text-gray-400 text-xs" v-if="index == 0">
                  {{ $t("subscription.detail.payment_history") }}
                </p>
              </div>

              <div>
                <div class="flex space-x-2 place-items-center">
                  <app-payment-detail-attempt-status :status="attempt.status" />

                  <p class="text-sm">
                    {{
                      $t("subscription.detail.payment_status." + attempt.status)
                    }}
                    ({{ attempt._id }})
                  </p>
                  <span
                    v-if="attempt.status == 'paid' && false"
                    class="flex place-items-center space-x-2"
                  >
                    <button>
                      <div
                        class="
                          px-2
                          py-1
                          border
                          rounded-md
                          flex flex-row
                          justify-center
                          bg-background
                          place-items-center
                          hover:bg-gray-100
                          space-x-1
                        "
                      >
                        <p class="text-xs">
                          {{ $t("subscription.detail.refund") }}
                        </p>
                        <app-icon-outline
                          name="ReceiptRefundIcon"
                          class="h-4 w-4 text-gray-700"
                        />
                      </div>
                    </button>
                  </span>
                  <!-- comment first retry payment -->
                  <!-- <span
                    v-if="attempt.status !== 'paid'"
                    class="flex place-items-center space-x-2"
                  >
                    <button @click.stop="null">
                      <div
                        class="
                          px-2
                          py-1
                          border
                          rounded-md
                          flex flex-row
                          justify-center
                          bg-background
                          place-items-center
                          hover:bg-gray-100
                          space-x-1
                        "
                      >
                        <p class="text-xs">
                          {{ $t("subscription.detail.retry_payment") }}
                        </p>
                        <app-icon-outline
                          name="RefreshIcon"
                          class="h-4 w-4 text-gray-700"
                        />
                      </div>
                    </button>
                  </span> -->
                </div>
                <div class="flex flex-col mt-2 ml-3.5 border-l-2 border-dotted">
                  <div class="ml-6 pb-4 space-y-1">
                    <p class="text-sm text-gray-500">
                      {{ attempt.payment_method?.name ?? "-" }}
                    </p>
                    <p class="text-xs text-gray-400">
                      {{
                        $moment(
                          Number(attempt.created_at?.$date?.$numberLong)
                        ).format("DD MMM YYYY hh:mm a")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="flex space-x-2 place-items-center px-5">
                <span
                  class="flex place-items-center space-x-1 p-1 filter grayscale"
                >
                  <app-icon-small-logo class="mb-1.5" />
                </span>
                <p class="text-sm">
                  {{ $t("subscription.detail.subscribed") }}
                </p>
              </div>
              <div class="flex flex-col ml-4 px-5">
                <div class="ml-6 pb-2">
                  <p class="text-xs text-gray-400">
                    {{
                      $moment(subscriber.created_at).format(
                        "DD MMM YYYY hh:mm a"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Package Details -->

          <div>
            <div class="space-y-2 p-5">
              <div>
                <p class="">
                  {{ $t("subscription.detail.package_details") }}
                </p>
              </div>

              <hr />

              <div class="space-y-2">
                <div class="block sm:grid grid-cols-5 text-sm">
                  <p class="col-span-2 text-gray-400" width="120px">
                    {{ $t("subscription.detail.package_name") }}
                  </p>
                  <p class="col-span-3">{{ subscriber.package?.name }}</p>
                </div>
                <div class="block sm:grid grid-cols-5 text-sm">
                  <p class="col-span-2 text-gray-400" width="120px">
                    {{ $t("subscription.detail.package_description") }}
                  </p>
                  <p class="col-span-3">
                    {{ subscriber.package?.description }}
                  </p>
                </div>
                <div class="block sm:grid grid-cols-5 text-sm">
                  <p class="col-span-2 text-gray-400" width="120px">
                    {{ $t("subscription.detail.amount_to_pay") }}
                  </p>
                  <p class="col-span-3">
                    {{
                      $formats.currency(
                        subscriber.package?.currency,
                        subscriber.package?.amount ?? 0.0
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Subscription Details -->

          <div>
            <div class="space-y-2 p-5">
              <div>
                <p class="">
                  {{ $t("subscription.detail.subscriber_details") }}
                </p>
              </div>

              <hr />

              <div class="space-y-2">
                <div class="block sm:grid grid-cols-5 text-sm">
                  <p class="col-span-2 text-gray-400 capitalize" width="120px">
                    {{ $t("subscription.detail.subscriber_name") }}
                  </p>
                  <p class="col-span-3">
                    {{ subscriber.name ?? "-" }}
                  </p>
                </div>
                <div class="block sm:grid grid-cols-5 text-sm">
                  <p class="col-span-2 text-gray-400" width="120px">
                    {{ $t("subscription.detail.subscriber_email") }}
                  </p>
                  <p class="col-span-3">
                    {{ subscriber.email ?? "-" }}
                  </p>
                </div>
                <div class="block sm:grid grid-cols-5 text-sm">
                  <p class="col-span-2 text-gray-400" width="120px">
                    {{ $t("subscription.detail.subscriber_phone") }}
                  </p>
                  <p class="col-span-3">
                    {{ subscriber.phone_no ?? "-" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
import PACKAGE_FREQUENCY from "@/utils/const/package_frequency";
export default {
  data() {
    return {
      showModalDetail: false,
    };
  },
  props: {
    subscriber: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["paymentStore/loading"];
    },
    message() {
      return this.$store.getters["paymentStore/message"];
    },
  },
  methods: {
    reverseAttempt() {
      var attempts = [];
      this.subscriber?.payment_links.forEach((payment_link) => {
        payment_link.attempts?.reverse().forEach((attempt) => {
          attempts.push(attempt);
        });
      });
      return attempts;
    },
    onClick(value) {
      this.$emit("onClick", value);
    },
    getStatus(status) {
      switch (status) {
        case "created":
          return "light";
        case "active":
          return "success";
        case "cancel":
          return "error";
        case "incomplete":
        case "to_be_canceled":
          return "warning";
        case "completed":
          return "success";
        case "deactivate":
          return "error";
        default:
          return "light";
      }
    },

    packageTerm(data) {
      switch (data.frequency) {
        case PACKAGE_FREQUENCY.DAILY:
          return data.frequency_num > 1 ? "days" : "day";
        case PACKAGE_FREQUENCY.WEEKLY:
          return data.frequency_num > 1 ? "weeks" : "week";
        case PACKAGE_FREQUENCY.MONTHLY:
          return data.frequency_num > 1 ? "months" : "month";
        case PACKAGE_FREQUENCY.YEARLY:
          return data.frequency_num > 1 ? "years" : "year";
      }
    },

    getTotalAmount(data) {
      return data.amount * data.frequency_num;
    },

    startBillCycle(data) {
      switch (data.start_after_type) {
        case "days":
          return data.start_after_num > 1 ? "days" : "day";
        case "weeks":
          return data.start_after_num > 1 ? "weeks" : "week";
        case "months":
          return data.start_after_num > 1 ? "months" : "month";
      }
    },
  },
};
</script>